import React, {useState, useEffect} from 'react';
import {Row, message, Skeleton, Empty} from 'antd';
import {connect} from 'react-redux';
import ProductItem from '~components/product-item';
import * as productActions from '~actions/product';
import {allDataLimit} from '~constants/account';

import './styles.scss';

const FavoriteContent = ({consumer, getFavorProducts, favoriteProducts, removeFavorite}) => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (!consumer || !consumer.id) return;

    const getFavoriteProducts = async () => {
      try {
        setLoading(true);
        await getFavorProducts({...allDataLimit});
      } finally {
        setLoading(false);
      }
    };

    getFavoriteProducts();
  }, [consumer]);

  useEffect(() => {
    if (favoriteProducts && favoriteProducts.length > 0) {
      setProducts(
        favoriteProducts.map(x => {
          x.isLike = true;
          return x;
        })
      );
    }
  }, [favoriteProducts]);

  const handleRemoveFavorite = async id => {
    const res = await removeFavorite(id);
    if (!res) return;
    message.success('Đã xóa khỏi sản phẩm yêu thích!');
  };

  return (
    <div id='favorite-content'>
      {loading && <Skeleton />}
      {!loading && products.length === 0 && (
        <Empty style={{marginLeft: '0'}} description='Không có sản phẩm' type='info' />
      )}
      {!loading && products.length > 0 && (
        <Row>
          {products.map(x => (
            <ProductItem key={x.id} item={x} favoritePage removeFavorite={handleRemoveFavorite} />
          ))}
        </Row>
      )}
    </div>
  );
};

export default connect(
  state => ({
    consumer: state.user.consumer,
    favoriteProducts: state.product.favoriteProducts,
  }),
  dispatch => ({
    getFavorProducts: () => dispatch(productActions.getFavoriteProducts()),
    removeFavorite: id => dispatch(productActions.removeFavorite(id)),
  })
)(FavoriteContent);
